import { toast } from "react-toastify";

/**
 * Error handler function for React Query.
 * Can be used to handle query and mutation errors globally.
 * @param {unknown} error - The error object or message.
 */
export const errorHandler = (error: unknown) => {
	console.error("Произошла ошибка:", error);
	if (error instanceof Error) {
		toast.error(`Ошибка: ${error.message}`);
	} else {
		toast.error("Произошла непредвиденная ошибка.");
	}
};

/**
 * Success handler function for React Query.
 * Can be used to handle successful mutations globally.
 * @param {unknown} data - The data returned by the successful mutation.
 */
export const successHandler = (data: unknown) => {
	console.log("Успех:", data);
	toast.success("Операция успешно завершена!");
};
