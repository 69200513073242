const ROUTE_PREFIX = process.env.REACT_APP_ROUTE_PREFIX || "";

export const ROUTES = {
	HOME: `${ROUTE_PREFIX}/`,
	LOGIN: `${ROUTE_PREFIX}/login`,
	CATEGORY: `${ROUTE_PREFIX}/categories`,
	USER: `${ROUTE_PREFIX}/users`,
	UNITS: `${ROUTE_PREFIX}/units`,
	ADMIN: `${ROUTE_PREFIX}/admin`,
	REQUESTS: `${ROUTE_PREFIX}/requests`,
	LOGS: `${ROUTE_PREFIX}/logs`,
} as const;

export const ROUTE_PATHS = Object.values(ROUTES);
