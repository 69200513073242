import React from "react";

/**
 * LoadingSpinner Component
 * A reusable spinner component for indicating loading state.
 * @returns {JSX.Element} - The rendered loading spinner component.
 */
const LoadingSpinner: React.FC = (): JSX.Element => {
	return (
		<div className="flex items-center justify-center h-screen">
			<div className="relative w-16 h-16">
				{/* Outer ring */}
				<div className="absolute w-full h-full border-4 rounded-full border-t-transparent border-main animate-spin"></div>

				{/* Inner ring */}
				<div className="absolute w-12 h-12 border-4 rounded-full top-2 left-2 border-main animate-spin-reverse"></div>
			</div>
		</div>
	);
};

export default LoadingSpinner;
