import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

interface PublicRouteProps {
	isAuthenticated: boolean;
	// eslint-disable-next-line
	component: React.ComponentType<any>;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({
	isAuthenticated,
	component: Component,
}) => {
	return isAuthenticated ? (
		<Navigate to={ROUTES.HOME} replace />
	) : (
		<Component />
	);
};
