import { getCookie } from "@utils/cookie";

/**
 * Function to check if the user is authenticated.
 * This checks the 'authToken' from cookies.
 *
 * @returns {boolean} - Returns true if authenticated, false otherwise.
 */
export const isAuthenticated = (): boolean => {
	return !!getCookie("authToken");
};
