import React, { FC } from "react";
import { SvgIconProps } from "./SvgIcon.props";

const SvgIcon: FC<SvgIconProps> = ({
	Icon,
	className = "",
	width = 24,
	height = 24,
	stroke = "currentColor",
	fill = "none",
	...props
}) => {
	return (
		<Icon
			className={className}
			width={width}
			height={height}
			stroke={stroke}
			fill={fill}
			{...props}
		/>
	);
};

export default SvgIcon;
