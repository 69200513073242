import React, { FC } from "react";
import { LayoutProps } from "./Layout.props";
import Sidebar from "@components/Sidebar/Sidebar";

const AdminLayout: FC<LayoutProps> = ({ children }) => {
	return (
		<div className="flex min-h-screen">
			{/* Sidebar */}
			<Sidebar />

			{/* Main content area */}
			<main className="flex-grow p-6 ml-64 bg-white">{children}</main>
		</div>
	);
};

export default AdminLayout;
