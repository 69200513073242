import React, { FC } from "react";
import clsx from "clsx";
import { PProps } from "./P.props";

const P: FC<PProps> = React.memo(
	({
		size = "p1",
		type = "regular",
		children,
		className = "",
		dangerouslySetInnerHTML,
		...props
	}): JSX.Element => {
		const sizeClasses = {
			p1: "text-p1",
			p2: "text-p2",
			p3: "text-p3",
			t2: "text-t2",
		};

		const typeClasses = {
			light: "font-light",
			regular: "font-normal",
			medium: "font-medium",
			semibold: "font-semibold",
			bold: "font-bold",
		};

		const classnames = clsx(
			"cursor-default font-sans",
			sizeClasses[size],
			typeClasses[type],
			className,
		);

		const contentProps = dangerouslySetInnerHTML
			? { dangerouslySetInnerHTML }
			: { children };

		return <p className={classnames} {...props} {...contentProps} />;
	},
);
export default P;
