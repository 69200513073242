import React, { Suspense } from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { routesConfig } from "./routesConfig";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import LoadingSpinner from "@components/LoadingSpinner";
import { ROUTES } from "constants/routes";
import { isAuthenticated } from "@utils/auth";
import AdminLayout from "@components/Layout/AdminLayout";

const AppRouter: React.FC = () => {
	const isUserAuthenticated = isAuthenticated();

	return (
		<Router>
			<Suspense fallback={<LoadingSpinner />}>
				{isUserAuthenticated ? (
					<AdminLayout>
						<Routes>
							{routesConfig.map(({ path, component: Component, type }) =>
								type === "private" ? (
									<Route
										key={path}
										path={path}
										element={
											<PrivateRoute
												isAuthenticated={isUserAuthenticated}
												component={Component}
											/>
										}
									/>
								) : (
									<Route
										key={path}
										path={path}
										element={<Navigate to={ROUTES.HOME} replace />}
									/>
								),
							)}

							<Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
						</Routes>
					</AdminLayout>
				) : (
					<Routes>
						{routesConfig.map(
							({ path, component: Component, type }) =>
								type === "public" && (
									<Route
										key={path}
										path={path}
										element={
											<PublicRoute
												isAuthenticated={isUserAuthenticated}
												component={Component}
											/>
										}
									/>
								),
						)}

						<Route path="*" element={<Navigate to={ROUTES.LOGIN} replace />} />
					</Routes>
				)}
			</Suspense>
		</Router>
	);
};

export default AppRouter;
