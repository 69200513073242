// src/router/PrivateRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";

interface PrivateRouteProps {
	isAuthenticated: boolean;
	// eslint-disable-next-line
	component: React.ComponentType<any>;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
	isAuthenticated,
	component: Component,
}) => {
	return isAuthenticated ? (
		<Component />
	) : (
		<Navigate to={ROUTES.LOGIN} replace />
	);
};
