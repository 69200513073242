import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import i18n from "i18next";
import { ROUTES } from "constants/routes";
import SvgIcon from "@components/common/SvgIcon/SvgIcon";
import {
	ChevronRightIcon,
	LayoutGridIcon,
	LogIcon,
	LogoMainIcon,
	LogoutIcon,
	MeauserIcon,
	RequestIcon,
	UserCircleIcon,
	UserIcon,
} from "@assets/icons";
import P from "@components/common/P/P";
import { toast } from "react-toastify";
import { removeCookie } from "@utils/cookie";

const sidebarItems = [
	{ label: "Категории", path: ROUTES.CATEGORY, icon: LayoutGridIcon },
	{ label: "Пользователи", path: ROUTES.USER, icon: UserIcon },
	{ label: "Единицы измерения", path: ROUTES.UNITS, icon: MeauserIcon },
	{ label: "Администраторы", path: ROUTES.ADMIN, icon: UserCircleIcon },
	{ label: "Заявки", path: ROUTES.REQUESTS, icon: RequestIcon },
	{ label: "Логи", path: ROUTES.LOGS, icon: LogIcon },
];

const languages = [
	{ code: "en", label: "English" },
	{ code: "ru", label: "Русский" },
	{ code: "tm", label: "Türkmençe" },
];

const Sidebar: React.FC = () => {
	const location = useLocation();
	const isActive = (path: string) => location.pathname === path;
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(
		i18n.language || "en",
	);

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language);
		setSelectedLanguage(language);
		setIsDropdownOpen(false);
	};

	const handleLogout = () => {
		removeCookie("authToken");
		removeCookie("authUsername");

		toast.success("Вы успешно вышли из системы.");
		window.location.reload();
	};

	return (
		<aside className="fixed top-0 left-0 flex flex-col justify-between w-64 h-full bg-white border-r border-stroke">
			<div className="grid grid-cols-1 p-4 border-b border-stroke">
				<SvgIcon
					Icon={LogoMainIcon}
					width={195}
					height={42}
					stroke="stroke-none"
				/>
			</div>

			<nav className="flex-grow p-3">
				<ul className="grid grid-flow-row grid-cols-1 gap-1 auto-rows-max">
					{sidebarItems.map((item) => (
						<li key={item.path}>
							<Link
								to={item.path}
								className={`flex items-center space-x-4 px-4 py-2 rounded-md transition duration-300 ${
									isActive(item.path)
										? "bg-shadeOne text-main border-l-4 border-main"
										: "text-grayThree hover:bg-neutral-100"
								}`}
							>
								<SvgIcon
									Icon={item.icon}
									width={22}
									height={22}
									className={
										isActive(item.path) ? "text-main" : "text-grayThree"
									}
								/>
								<P
									size="t2"
									type="medium"
									className={`cursor-pointer
										${isActive(item.path) ? "text-main" : "text-grayThree"}
									`}
								>
									{item.label}
								</P>
							</Link>
						</li>
					))}
				</ul>
			</nav>

			<div className="relative grid gap-4 px-8 py-5 border-t grid-cols-maxTwo place-content-between place-items-center border-stroke">
				<div
					className="grid gap-2 cursor-pointer grid-cols-maxTwo place-items-center"
					onClick={handleLogout}
				>
					<SvgIcon Icon={LogoutIcon} width={22} height={22} />
					<P size="p3" type="regular" className="cursor-pointer">
						Выход
					</P>
				</div>

				<div
					onClick={() => setIsDropdownOpen(!isDropdownOpen)}
					className="grid grid-cols-maxTwo gap-2 place-items-center px-2 py-1.5 rounded-xl bg-shadeOne cursor-pointer relative z-20"
				>
					<P size="t2" type="medium" className="cursor-pointer">
						{selectedLanguage.toUpperCase()}
					</P>
					<SvgIcon Icon={ChevronRightIcon} width={18} height={18} />
				</div>

				<AnimatePresence>
					{isDropdownOpen && (
						<motion.div
							initial={{ opacity: 0, y: -10 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: -10 }}
							transition={{ duration: 0.3 }}
							className="absolute right-0 z-10 w-40 py-2 bg-white border rounded-lg shadow-lg bottom-16 border-stroke"
						>
							<ul className="grid grid-flow-row auto-rows-max">
								{languages.map(({ code, label }) => (
									<li
										key={code}
										onClick={() => handleLanguageChange(code)}
										className={`flex items-center px-4 py-2 cursor-pointer ${
											code === selectedLanguage ? "text-main" : "text-grayThree"
										} hover:bg-gray-100`}
									>
										<P size="p3" type="medium" className="cursor-pointer">
											{label}
										</P>
									</li>
								))}
							</ul>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</aside>
	);
};

export default Sidebar;
