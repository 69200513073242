import React, { Suspense } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { Provider } from "react-redux";
// import store from "./store";
import { ErrorBoundary } from "./modules/errors/ErrorBoundary";
import queryClient from "./config/queryClient";
import AppRouter from "./router/Router";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

const App: React.FC = (): JSX.Element => {
	return (
		<ErrorBoundary>
			{/* <Provider store={store}> */}
			<QueryClientProvider client={queryClient}>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					limit={3}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
					transition={Zoom}
				/>
				<I18nextProvider i18n={i18n}>
					<Suspense fallback={<div>Loading...</div>}>
						<AppRouter />
					</Suspense>
				</I18nextProvider>

				{process.env.NODE_ENV === "development" && (
					<ReactQueryDevtools initialIsOpen={false} position="bottom" />
				)}
			</QueryClientProvider>
			{/* </Provider> */}
		</ErrorBoundary>
	);
};

export default App;
