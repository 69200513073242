/**
 * Set a cookie with a specified expiration time (in hours).
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} hours - Expiration time in hours.
 */
export const setCookie = (name: string, value: string, hours: number) => {
	const date = new Date();
	date.setTime(date.getTime() + hours * 60 * 60 * 1000);
	document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

/**
 * Get the value of a cookie by name.
 * @param {string} name - The name of the cookie.
 * @returns {string | null} - The value of the cookie or null if not found.
 */
export const getCookie = (name: string): string | null => {
	const nameEQ = `${name}=`;
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		let c = cookies[i];
		while (c.charAt(0) === " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
};

export const removeCookie = (name: string) => {
	document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
