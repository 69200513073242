import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import ru from "./ru.json";
import tm from "./tm.json";

/**
 * Initialize i18next with translations for each section.
 * The resources object groups translations by language and section.
 */
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en,
			ru,
			tm,
		},

		fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE || "en",
		debug: process.env.NODE_ENV === "development",
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: [
				"querystring",
				"cookie",
				"localStorage",
				"navigator",
				"htmlTag",
				"path",
				"subdomain",
			],
			caches: ["localStorage", "cookie"],
		},
	});

export default i18n;
