import { lazy } from "react";
import { ROUTES } from "../constants/routes";

const Home = lazy(() => import("../pages/Home"));
const Login = lazy(() => import("../pages/Login"));
const Category = lazy(() => import("../pages/Category"));
const Unit = lazy(() => import("../pages/Unit"));
const Administrator = lazy(() => import("../pages/Administrator"));
const Log = lazy(() => import("../pages/Log"));
const Requests = lazy(() => import("../pages/Request"));
const User = lazy(() => import("../pages/User"));

export type RouteType = "public" | "private";

export interface AppRoute {
	path: string;
	// eslint-disable-next-line
	component: React.LazyExoticComponent<React.ComponentType<any>>;
	type: RouteType;
}

export const routesConfig: AppRoute[] = [
	{
		path: ROUTES.LOGIN,
		component: Login,
		type: "public",
	},
	{
		path: ROUTES.HOME,
		component: Home,
		type: "private",
	},
	{
		path: ROUTES.CATEGORY,
		component: Category,
		type: "private",
	},
	{
		path: ROUTES.UNITS,
		component: Unit,
		type: "private",
	},
	{
		path: ROUTES.ADMIN,
		component: Administrator,
		type: "private",
	},
	{
		path: ROUTES.LOGS,
		component: Log,
		type: "private",
	},
	{
		path: ROUTES.REQUESTS,
		component: Requests,
		type: "private",
	},
	{
		path: ROUTES.USER,
		component: User,
		type: "private",
	},
];
