import {
	QueryClient,
	QueryClientConfig,
	QueryCache,
	MutationCache,
} from "@tanstack/react-query";
import { errorHandler, successHandler } from "./apiClient";

const defaultOptions: QueryClientConfig["defaultOptions"] = {
	queries: {
		retry: 2,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 5,
		gcTime: 1000 * 60 * 10,
	},
	mutations: {
		retry: 1,
	},
};

const queryCache = new QueryCache({
	onError: (error) => {
		console.error("Global Query Error:", error);
		errorHandler(error);
	},
});

const mutationCache = new MutationCache({
	onError: (error) => {
		console.error("Global Mutation Error:", error);
		errorHandler(error);
	},
	onSuccess: (data) => {
		console.log("Global Mutation Success:", data);
		successHandler(data);
	},
});

const queryClient = new QueryClient({
	queryCache,
	mutationCache,
	defaultOptions,
});

export default queryClient;
